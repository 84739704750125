/* @flow */
import React, { createContext, type Node, PureComponent } from 'react'
import ExternalService from '../components/utils/ExternalService'
import request from '../components/utils/Request'
import { sendWidgetClick } from '../components/utils/ga4Events'
import { setWindowEcoCpexTuple, setWindowEcoSid } from '../utils/windowEco'
import { getAdsPersonalizationConsent } from '../../lib/utils/didomiConsent'

const _ = require('lodash')

type EmailProviderProps = {|
  children: Node,
  options: {
    proxyOptions: Object,
    serviceUrlPattern: string,
    xhrLogoutUri: string,
    isTracked: boolean,
    cookieNameConstants: Object,
    cookies: Object,
    ecoIdentityReloadIntervalMs: string
  },
  variant?: string
|}

type EmailProviderState = {|
  inbox: Object,
  userInfo: {
    email?: string,
    firstName?: string,
    lastName?: string,
    avatar?: string,
    hasMailbox?: boolean,
    userService?: string,
    userUuid?: string
  },
  isLoadingInbox?: boolean,
  isLoadingUserInfo: boolean | null,
  hasConsent: boolean,
  isEcoIdDispatched: boolean
|}

const RELOAD_INTERVAL_MS = 120000

const EmailContext: Object = createContext()

const EmailConsumer = EmailContext.Consumer

class EmailProvider extends PureComponent<EmailProviderProps, EmailProviderState> {
  emailRefreshingInterval: IntervalID
  userInfoRefreshingInterval: IntervalID
  ecoIdentityReloadIntervalMs: number
  handleLogoutClick: (event: Object) => void
  constructor (props: EmailProviderProps) {
    super(props)

    const { ecoIdentityReloadIntervalMs } = props.options
    this.ecoIdentityReloadIntervalMs = Number(ecoIdentityReloadIntervalMs)

    this.state = {
      inbox: {},
      userInfo: {},
      isLoadingInbox: false,
      isLoadingUserInfo: null,
      hasConsent: false,
      isEcoIdDispatched: false
    }

    this.handleLogoutClick = this.handleLogoutClick.bind(this)
  }

  componentDidMount () {
    this.setDidomiConsent()
    this.loadInbox()
    this.loadUserInfo()
  }

  componentWillUnmount () {
    clearInterval(this.emailRefreshingInterval)
    clearInterval(this.userInfoRefreshingInterval)
  }

  componentDidUpdate (_: any, prevState: EmailProviderState) {
    const {
      options: {
        cookieNameConstants,
        cookies
      },
      variant
    } = this.props

    const { hasConsent } = this.state

    if (hasConsent) {
      if (variant === 'ProbabilisticId') {
        this.handleSetWindowEcoSid(cookies, cookieNameConstants)
      }
      this.handleSetWindowCpexTuple(prevState)
    }
  }

  handleSetWindowCpexTuple (prevState: EmailProviderState) {
    const {
      options: {
        isTracked
      }
    } = this.props

    const {
      isLoadingUserInfo,
      userInfo: {
        userService,
        userUuid
      }
    } = this.state

    const canReadUserInfo = (isLoadingUserInfo === false)
    if (!canReadUserInfo) {
      return
    }

    const isUserLoggedIn = (userService && userUuid)
    if (!isUserLoggedIn) {
      this.tryDispatchEcoIdReadyEvent()
      return
    }

    if (!isTracked) {
      return
    }

    setWindowEcoCpexTuple({ userUuid, userService })
    this.tryDispatchEcoIdReadyEvent()
  }

  tryDispatchEcoIdReadyEvent () {
    const canNotifySasic = !_.isEmpty(window.eco) && !this.state.isEcoIdDispatched
    if (!canNotifySasic) {
      return
    }

    const notifySasicAboutWindowEco = () => {
      window.dispatchEvent(new Event('ecoIdReady'))
      this.setState({
        isEcoIdDispatched: true
      })
    }

    notifySasicAboutWindowEco()
  }

  handleSetWindowEcoSid (cookies: Object, cookieNameConstants: Object) {
    const sidCookie = cookies[cookieNameConstants.sid]
    if (sidCookie) {
      setWindowEcoSid(sidCookie)
    }
  }

  setDidomiConsent () {
    window.didomiOnReady.push(() => {
      this.setState({ hasConsent: getAdsPersonalizationConsent() })
    })
  }

  loadInbox () {
    const {
      options: {
        proxyOptions,
        serviceUrlPattern
      }
    } = this.props

    if ((this.shouldProcessInboxRequestEcoIdentity())) {
      const serviceHelper = new ExternalService(proxyOptions)
      const serviceUri = serviceHelper.getServiceUrl(serviceUrlPattern)
      this.loadInboxForFirstTime(serviceUri)
      this.startPeriodicallyLoadingInbox(serviceUri)
    }
  }

  loadUserInfo () {
    if (this.shouldProcessUserInfoRequest()) {
      this.loadIdentitaUserInfo()
      this.startPeriodicallyLoadingUserInfo()
    } else {
      this.setState({
        isLoadingUserInfo: false
      })
    }
  }

  shouldProcessInboxRequestEcoIdentity () {
    const {
      options: {
        cookieNameConstants,
        cookies
      }
    } = this.props

    const sessionCookieName = cookieNameConstants.sessionCookie
    const uprofileCookieName = cookieNameConstants.uprofileCookie

    return cookies[sessionCookieName] || cookies[uprofileCookieName]
  }

  shouldProcessUserInfoRequest () {
    const {
      options: {
        cookieNameConstants,
        cookies
      }
    } = this.props

    const sessionCookieName = cookieNameConstants.sessionCookie
    const aprofileCookieName = cookieNameConstants.aprofileCookie

    return cookies[sessionCookieName] || cookies[aprofileCookieName]
  }

  loadInboxForFirstTime (serviceUri: string) {
    this.setState({
      isLoadingInbox: true
    })

    this.sendRequest(serviceUri)
      .then(inbox => {
        this.setState({ inbox })
        window.localStorage.setItem('tracked', 'true')
      })
      .finally(() => {
        this.setState({
          isLoadingInbox: false
        })
      })
  }

  startPeriodicallyLoadingInbox (serviceUri: string) {
    this.emailRefreshingInterval = setInterval(() => {
      this.sendRequest(serviceUri).then(inbox => {
        this.setState({
          inbox
        })
      }).catch(() => {
      })
    }, RELOAD_INTERVAL_MS)
  }

  sendRequest (serviceUri: string): Promise<Object> {
    return new Promise((resolve, reject) => {
      const config = {
        url: serviceUri,
        withCredentials: true,
        proxy: false
      }

      request(config).then(response => {
        const inbox = (typeof response.data === 'object') ? response.data : {}
        return resolve(inbox)
      }).catch(error => {
        return reject(error)
      })
    })
  }

  handleLogoutClick (event: Object) {
    this.setState({
      isLoadingInbox: true
    })

    const {
      options: {
        xhrLogoutUri
      }
    } = this.props
    clearInterval(this.emailRefreshingInterval)
    const eventTargetUri = event.target.getAttribute('href')
    this.sendLogoutRequest(xhrLogoutUri).then(() => {
      this.loadInbox()
    }).catch(() => {
      document.location.href = eventTargetUri
    }).finally(() => {
      this.setState({
        isLoadingInbox: false
      })
    })
    sendWidgetClick({
      name: 'email',
      click: {
        type: 'link',
        value: 'logout'
      }
    })
  }

  sendLogoutRequest (logoutUri: string): Promise<void> {
    return new Promise((resolve, reject) => {
      request({
        url: logoutUri,
        withCredentials: true
      }).then(() => {
        return resolve()
      }).catch(error => {
        return reject(error)
      })
    })
  }

  async loadIdentitaUserInfo () {
    this.setState({
      isLoadingUserInfo: true
    })

    this.fetchUserInfo().finally(() => {
      this.setState({
        isLoadingUserInfo: false
      })
    })
  }

  startPeriodicallyLoadingUserInfo () {
    if (!_.isNumber(this.ecoIdentityReloadIntervalMs) || !_.isFinite(this.ecoIdentityReloadIntervalMs)) {
      return
    }

    this.userInfoRefreshingInterval = setInterval(() => {
      if (this.shouldProcessUserInfoRequest()) {
        this.fetchUserInfo()
      }
    }, this.ecoIdentityReloadIntervalMs)
  }

  async fetchUserInfo () {
    return fetch('/userinfo').then((response) =>
      response.json().then(({
        email,
        firstname: firstName,
        lastname: lastName,
        avatar,
        has_mailbox: hasMailbox,
        service: userService,
        uuid: userUuid
      }) => {
        this.setState({
          userInfo: {
            email,
            firstName,
            lastName,
            avatar,
            hasMailbox,
            userService,
            userUuid
          }
        })
      })
    )
  }

  render () {
    const {
      props: {
        children
      },
      state
    } = this

    const { isLoadingInbox, isLoadingUserInfo } = state
    const isLoading = isLoadingInbox && isLoadingUserInfo

    const contextValue = Object.assign({}, state, {
      handleLogoutClick: this.handleLogoutClick,
      isLoading
    })

    return (
      <EmailContext.Provider value={contextValue}>
        { children }
      </EmailContext.Provider>
    )
  }
}

export {
  EmailProvider,
  EmailConsumer
}
