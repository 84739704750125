const WindowEcoProps = {
  TOKEN: 'token',
  SERVICE: 'service',
  SID: 'sid'
}

export const setWindowEcoSid = (sidCookie) => {
  window.eco = window.eco || {}
  window.eco[WindowEcoProps.SID] = sidCookie
}

export const setWindowEcoCpexTuple = ({ userUuid, userService }) => {
  window.eco = window.eco || {}
  window.eco[WindowEcoProps.TOKEN] = userUuid
  window.eco[WindowEcoProps.SERVICE] = userService
}
